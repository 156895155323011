
const requireComponent = require.context(
  '@/cr/components/icons',
  false,
  /Icon[A-Z][a-zA-Z0-9]+\.(vue)$/
)

const iconComponents = []

requireComponent.keys().forEach((fileName) => {
  const componentConfig = requireComponent(fileName)
  const componentName = fileName
    .split('/')
    .pop()
    .replace(/\.\w+$/, '')
    .replace(/^Icon/, '')
    .replace(/([a-z])([A-Z])/g, '$1_$2')

  iconComponents.push({
    name: componentName.toLowerCase(),
    component: componentConfig.default || componentConfig,
  })
})

export default {
  metaInfo() {
    return {
      title: 'Icons',
    }
  },
  data() {
    return {
      iconComponents: iconComponents,
    }
  },
}
